<template>
  <AppModal
    v-if="true"
    :value="true"
    :dismissible="true"
    :content-centered="true"
    :persistent="true"
    class="action-required-modal"
  >
    <!-- Main content -->
    <div class="text-center">
      <template>
        <div class="pb-5 flex justify-center">
          <img :src="require('../../../public/img/icons/warning.svg')" alt="Warning sign" />
        </div>
      </template>
      <h1 class="mb-4 type-h1">Pending actions required</h1>
      <p class="text-text-gray-primary type-body">
        To access these features you will need to submit your pending actions
      </p>

      <!-- Footer Desktop -->
      <div class="pt-6">
        <!-- Go to FX web -->
        <AppButton
          v-if="mq.current !== 'xs'"
          type="submit"
          class="w-full my-2"
          @click="goToPendingActions"
        >
          Continue
        </AppButton>
      </div>
    </div>

    <!-- Footer Mobile-->
    <template v-if="mq.current == 'xs'" #footer>
      <!-- Go to FX web -->
      <AppModalFooter>
        <AppButton class="documentary-evidence-button" @click="goToPendingActions"
          >Continue</AppButton
        >
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'

import AppButton from '@/components/AppButton/AppButton'
import AppModal from '@/components/AppModal/AppModal'
import AppModalFooter from '@/components/AppModalFooter/AppModalFooter'
import { useAuthStore } from '../../stores/auth'

export const errorTypeData = Object.freeze({
  general: {
    titleText: 'Something went wrong!',
    paragraphText: 'Sorry, there’s an unexpected error. Please try again later.',
    buttonText: 'Try again',
    stepId: -1,
    showBackArrow: false,
  },
  duplicate: {
    titleText: 'You’re already registered',
    paragraphText: 'Looks like your company already has an account. Try to login instead.',
    buttonText: 'Login',
    stepId: 0,
    showBackArrow: true,
  },
  natureOfBusiness: {
    titleText: 'We can’t finish your registration',
    paragraphText: 'Sorry, we’re unable to serve the industry you’ve selected',
    buttonText: 'Okay',
    stepId: 0,
    showBackArrow: true,
  },
})

export default {
  name: 'RegisterError',
  components: {
    AppButton,
    AppModal,
    AppModalFooter,
  },
  props: {
    errorType: {
      type: String,
      default: 'general',
      validator: (value) => {
        return Object.prototype.hasOwnProperty.call(errorTypeData, value)
      },
    },
  },
  setup(props) {
    const router = useRouter()
    const authStore = useAuthStore()

    const errorType = errorTypeData[props.errorType]
    const showBackArrow = errorType.showBackArrow
    const titleText = errorType.titleText
    const paragraphText = errorType.paragraphText
    const buttonText = errorType.buttonText

    const onClick = () => {
      if (props.errorType === 'duplicate') {
        window.location.href = authStore.loginUrl
      } else if (props.errorType === 'natureOfBusiness') {
        window.location = 'https://www.xe.com/mt/ca-business/' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      } else {
        // remove "leave site" listener
        window.removeEventListener('beforeunload', window.handleWindowClose)
        router.push({ name: 'PendingActions' }) // TODO: add correct redirect route
      }
    }

    const onContactUs = () => {
      window.location = 'https://www.xe.com/contact/faq/' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    const goToPendingActions = () => router.push({ name: 'PendingActions' })

    return {
      showBackArrow,
      buttonText,
      onClick,
      onContactUs,
      goToPendingActions,
      paragraphText,
      titleText,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.action-required {
  &-modal {
    /deep/ .overlay-content {
      height: 100%;
    }

    /deep/ .card {
      @screen sm {
        @apply mt-12;
      }
    }
  }
}
.app-icon {
  @apply mt-6;

  span {
    @apply text-4xl;
  }
}
</style>
